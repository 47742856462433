<template>
  <div class="forgot-wrapper">
    <div class="container">
      <header>
        <div class="logo">
          <h1 @click="goToHome()">
            <img src="../assets/nav/HectocommerceLogo.svg">
          </h1>
        </div>
      </header>
    </div>
    <section>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <!-- <img
            class="bg-img"
            src="../assets/forgotPassword/Intersection 3.png"
          /> -->
          <img
            class="forgot-img"
            src="../assets/forgotPassword/Forgot password-amico.svg"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="content">
            <div class="main-content">
              <h1 class="forgot-title">Forgot your password !</h1>
              <h3>We Got your Back !</h3>
              <div class="forgot-form mt-5">
                <h2>Enter your Registered Email ID</h2>
                <w-text-input
                  :labelName="'Email ID'"
                  :fieldType="'text'"
                  :disabled="false"
                  :defaultValue="emailValue"
                  :validation="'regex_Email'"
                  :newInputStyle="'forgotInput'"
                  :placeholder="'Your Email'"
                  :fieldName="'Email Address'"
                  :labelStyle="'login-input-label'"
                  @textInputChange="getEmailAddress($event)"
                />
                <div class="reset-btn text-center mt-5">
                  <w-button-input
                    :buttonClass="'auth-button'"
                    :buttonStyle="'auth-button-style'"
                    @buttonClicked="forgotPasswordButton()"
                    :disabled="isForgotPasswordDetail"
                    :label="'RESET PASSWORD'"
                  />
                </div>
              </div>
            </div>
            <div class="copy-right">
              <p>
                Copyright © 2021 HectoCommerce, A DIATOZ Product, All rights
                reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- error modal -->
    <Modal
      :trigger="alert"
      :title="'D-Ecommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
    />

  </div>
</template>

<script>
import TextInput from "../widgets/TextInput.vue";
import ButtonInput from "../widgets/InputButton.vue";
import { resetPasswordInit } from "../Service/LoginService.js";
import Modal from "../widgets/ModalWidget";

export default {
  components: {
    "w-text-input": TextInput,
    "w-button-input": ButtonInput,
    Modal
  },
  data() {
    return {
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      emailValue: "",
      passwordValue: "",
    };
  },

  computed: {
    isForgotPasswordDetail() {
      let re = /\S+@\S+\.\S+\S+/;
        return !re.test(this.emailValue);
    },
  },

  methods: {
    getEmailAddress(event) {
      this.emailValue = event;
    },

    forgotPasswordButton() {
      // let data = {
      //   email: this.emailValue,
      // };
      resetPasswordInit(this.emailValue)
        .then((res) => {
            this.alert = true;
            this.isConfirmAlert = false;
            this.alertMsg = "Please check your Email and click on the link";
            this.alertType = "Success";
            this.emailValue="";
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
      
    },

    // close modal
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    goToHome(){
      this.$router.push("/")
    },

  },
};
</script>

<style scoped>
.forgot-wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #fff;
}
section {
  background: url("../assets/forgotPassword/Intersection 3.png") no-repeat;
  background-size: 50% 100%;
  background-position: left;
  height: 100vh;
}
.forgot-wrapper::-webkit-scrollbar {
  display: none;
}
.logo {
  font-family: "bebas-kai";
  font-size: 2rem;
  color: #464646;
  cursor: pointer;
}
.forgot-img {
  position: fixed;
  top: 15%;
  left: 15%;
}
.content {
  margin: 8% 15% 0;
}
.forgot-title {
  font: normal normal normal 40px/40px "bebas-kai";
  color: #464646;
  text-align: center;
}
.content h3 {
  font: normal normal normal 30px/40px "bebas-kai";
  color: #464646;
  text-align: center;
}
.forgot-form h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2rem;
}
p {
  font: normal normal normal 10px/12px Quasimoda;
  color: #0475a1;
  text-transform: uppercase;
  position: fixed;
  bottom: 0;
  right: 130px;
}
@media screen and (max-width: 768px) {
  .forgot-title {
    font: normal normal normal 20px/20px "bebas-kai";
  }
  .content {
    margin: 0 5% 0;
  }
  .content h3 {
    font-size: 14px;
  }
  section {
    background-position: left bottom;
    background-size: 85% 50%;
  }
  .forgot-img {
    width: 45%;
    top: 60%;
    bottom: 10%;
    left: 20%;
  }
  .forgot-form {
    margin-top: 10px !important;
  }
  .forgot-form h2 {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  .reset-btn {
    margin-top: 20px !important;
  }
  p {
    display: none;
  }
}
</style>